import { COUNTRY_CODES, LANGUAGES_CODE, LONG_LANG_CODES, TLD } from "../../../constant/Index.js";
import PAGE_URLS from "../../../constant/Index.js";

const getLanguageCode = () => {
  
  switch (PAGE_URLS.TLD) {
    case TLD.Denmark:
      return LONG_LANG_CODES.Denmark;
    case TLD.Germany:
      return LONG_LANG_CODES.Germany;
    case TLD.Norwegian:
      return LONG_LANG_CODES.Norwegian;
    case TLD.Sweden:
      return LONG_LANG_CODES.Sweden;
    case TLD.UK:
      return LONG_LANG_CODES.UK;
    default:
      return "en";
  }
};
const getLanguageFromDomain = () => {

  switch (PAGE_URLS.TLD) {
    case TLD.Denmark:
      return LANGUAGES_CODE.Danish;
    case TLD.Germany:
      return LANGUAGES_CODE.German;
    case TLD.Norwegian:
      return LANGUAGES_CODE.Norwegian;
    case TLD.Sweden:
      return LANGUAGES_CODE.Swedish;
    case TLD.UK:
      return LANGUAGES_CODE.English;
    default:
      return LANGUAGES_CODE.English;
  }
};

const checkRegionExist = () => {

  switch (PAGE_URLS.TLD) {
    case TLD.Denmark:
      return true;
    case TLD.Germany:
      return true;
    case TLD.Norwegian:
      return true;
    case TLD.Sweden:
      return true;
    case TLD.UK:
      return true;
    default:
      return false
  }
};

const fnGetCountryCode =()=> {

  switch (PAGE_URLS.TLD) {
    case TLD.Denmark:
      return COUNTRY_CODES.denmark;
    case TLD.Germany:
      return COUNTRY_CODES.germany;
    case TLD.Norwegian:
      return COUNTRY_CODES.norway;
    case TLD.Sweden:
      return COUNTRY_CODES.sweden;
    case TLD.UK:
      return COUNTRY_CODES.uk;
    default:
      return COUNTRY_CODES.uk;
  }

};

const fnGetCountryFlag =(code)=>{
 console.log(code)
  switch (code) {
    case COUNTRY_CODES.denmark:
      return 'https://flagcdn.com/w320/dk.png';
    case COUNTRY_CODES.germany:
      return 'https://flagcdn.com/w320/de.png';
    case COUNTRY_CODES.norway:
      return 'https://flagcdn.com/w320/no.png';
    case COUNTRY_CODES.sweden:
      return 'https://flagcdn.com/w320/se.png';
    case COUNTRY_CODES.uk:
      return 'https://flagcdn.com/w320/gb.png';
    default:
      return 'https://flagcdn.com/w320/gb.png';
  }
};

export { getLanguageFromDomain, checkRegionExist, fnGetCountryCode, fnGetCountryFlag ,getLanguageCode}
