import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import "../assets/globals.css";
import "../assets/devClasses.css";
import RoutesLink from "../constant/RoutesLink";
import PrivateRoute from "./PrivateRoute.jsx";
import Loader from "../assets/Loader.jsx";
import SetPasswordPage from "../pages/setPassword/SetPasswordPage.jsx";
import NotFound from "../pages/notFound/NotFound.js";

// Lazy load components
const Home = lazy(() => import("../pages/home/Home"));
const Hotels = lazy(() => import("../pages/hotels/Hotels"));
const HotelDetail = lazy(() => import("../pages/hotelDetail/HotelDetail"));
const LeadOffer = lazy(() => import("../pages/leadOffer/LeadOffer"));
const ContractPage = lazy(() => import("../pages/contractPage/ContractPage"));
const About = lazy(() => import("../pages/about/About"));
const Info = lazy(() => import("../pages/info/Info"));
const Page = lazy(() => import("../pages/page/Page"));
const Referencer = lazy(() => import("../pages/referencer/Referencer"));
const PrivacyPolicy = lazy(() => import("../pages/privacyPolicy/PrivacyPolicy"));
const TradeConditions = lazy(() => import("../pages/tradeConditions/TradeConditions"));
const ContractPassenger = lazy(() => import("../pages/contractPassenger/ContractPassenger"));
const LeadsPage = lazy(() => import("../pages/leadsPage/LeadsPage"));
const LeadOffersPage = lazy(() => import("../pages/leadOffersPage/LeadOffersPage"));
const LeadContractPage = lazy(() => import("../pages/leadContractPage/LeadContractPage"));
const ProfilePage = lazy(() => import("../pages/profilePage/ProfilePage"));
const MessagePage = lazy(() => import("../pages/messagesPage/MessagePage"));
const FaqsPage = lazy(() => import("../pages/faqPage/FaqsPage"));

function ReactRoutes() {
  return (
    <BrowserRouter>
      <Suspense fallback={
          <div className={ "loader__div"}>
          <Loader />
        </div>
      }>
        <Routes>
          <Route path={RoutesLink.HOME} element={<Layout />}>
            <Route index element={<Home />} />
            <Route path={RoutesLink.HOTELS} element={<Hotels />} />
            <Route path={RoutesLink.ABOUT} element={<About />} />
            <Route path={RoutesLink.INFO} element={<Info />} />
            <Route path={RoutesLink.PRIVACY_POLICY} element={<PrivacyPolicy />} />
            <Route path={RoutesLink.TRADES_CONDITIONS} element={<TradeConditions />} />
            <Route path={RoutesLink.REFERENCER} element={<Referencer />} />
            <Route path={RoutesLink.PAGE} element={<Page />} />
            <Route path={RoutesLink.HOTEL_DETAIL} element={<HotelDetail />} />
            <Route path={RoutesLink.LEAD_OFFER} element={<LeadOffer />} />
            <Route path={RoutesLink.LEAD_CONTRACT} element={<ContractPage />} />
            <Route path={RoutesLink.CONTRACT_PASSENGER} element={<ContractPassenger />} />
            <Route path={RoutesLink.SET_PASSWORD} element={<SetPasswordPage />} />
            <Route path={RoutesLink.NOT_FOUND} element={<NotFound />} />

            <Route element={<PrivateRoute />}>
              <Route path={RoutesLink.LEADS_PAGE} element={<LeadsPage />} />
              <Route path={RoutesLink.LEADS_OFFERS_PAGE} element={<LeadOffersPage />} />
              <Route path={RoutesLink.LEADS_CONTRACT_PAGE} element={<LeadContractPage />} />
              <Route path={RoutesLink.PROFILE_PAGE} element={<ProfilePage />} />
              <Route path={RoutesLink.MESSAGE_PAGE} element={<MessagePage />} />
              <Route path={RoutesLink.FAQS} element={<FaqsPage />} />
            </Route>

            <Route path="*" element={"Not Found"} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default ReactRoutes;
