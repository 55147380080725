import React from 'react'
import { useTranslation } from 'react-i18next'

const NotFound = () => {

  const {t} = useTranslation()

  return (
    <div class="flex min-h-[60dvh] w-full flex-col items-center justify-center overflow-hidden bg-[#FFF] max-md:px-[32px]">
  <div class="relative">
    <h1 class="text-[190px] font-medium text-[#4A6DA7] max-md:text-[148px]">{t('page_not_found.404') }</h1>
    <div class="absolute left-[-160px] top-[70px] flex w-fit -rotate-12 flex-col items-center gap-2 rounded-[24px] bg-[#F6F5F3] p-4">
      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4585 5.76742L14.6767 6.18577L15.2176 12.368L10.4636 8.37897L7.37826 12.056L12.1323 16.0451L5.94998 16.5859L6.36832 21.3677L12.5506 20.8268L8.56152 25.5807L12.2385 28.6662L16.2276 23.9121L16.7685 30.0944L21.5502 29.6761L21.0093 23.4938L25.7634 27.4829L28.8487 23.8059L24.0947 19.8168L30.277 19.2759L29.8586 14.4942L23.6763 15.0351L27.6655 10.2811L23.9885 7.19569L19.9994 11.9497L19.4585 5.76742Z" fill="#FF8942" />
      </svg>
      <span class="font-medium text-[#333]"> {t('page_not_found.not_found') }</span>
    </div>
    <div class="absolute right-[-160px] top-[100px] flex w-fit rotate-12 items-center gap-2 rounded-[24px] bg-[#F6F5F3] px-4 py-2">
      💥
      <span class="font-medium text-[#333]"> {t('page_not_found.error') } </span>
    </div>
    <svg class="absolute right-[-200px] top-[160px]" xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M29.5 7H26.5V20.2832L21.524 7.96722L18.7425 9.09104L23.8474 21.726L14.2114 12.0901L12.0901 14.2114L21.3257 23.447L9.35706 18.2178L8.15596 20.9669L20.8202 26.5H7V29.5H20.8202L8.15597 35.0331L9.35706 37.7822L21.3257 32.553L12.0901 41.7886L14.2114 43.9098L23.8474 34.274L18.7425 46.909L21.524 48.0327L26.5 35.7169V49H29.5V35.7169L34.476 48.0327L37.2574 46.909L32.1528 34.274L41.7886 43.9098L43.9098 41.7886L34.6742 32.553L46.643 37.7822L47.8439 35.0331L35.1799 29.5H49V26.5H35.1797L47.8439 20.9669L46.643 18.2178L34.6742 23.447L43.9098 14.2114L41.7886 12.0901L32.1528 21.726L37.2574 9.09104L34.476 7.96722L29.5 20.2832V7Z" fill="#333333" />
    </svg>
  </div>
  <div class="flex flex-col items-center gap-8">
    <h1 class="text-center text-[48px] font-medium text-[#333] max-md:text-[36px]">{t('page_not_found.title') } </h1>
    <p class="max-w-[620px] text-center text-xl text-[#555] max-md:text-[16px]">{t('page_not_found.description') }</p>
    <a href="/" class="w-fit rounded-2xl bg-[#FF6B13] px-6 py-3 text-[#FFF]">{t('page_not_found.back_to_home') }</a>
  </div>
</div>
  )
}

export default NotFound