import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./app/i18N/I18n";
import { Provider } from "react-redux";
import { store } from "./app/redux/store"
import CookieConsentManager from './app/components/shared/cookie-consent/CookieConsentManager';
import Cookies from 'js-cookie';
import { Config } from "./app/constant/Index";

// Render the cookies consent banner outside the root element
// if (!Cookies.get(Config.__COOKIE__CONSENT__)) {
//   ReactDOM.createRoot(document.getElementById("cookies-consent")).render(
//     <CookieConsentManager />
//   );
//   }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

